import React from 'react';
import ReactDOM from 'react-dom';
// import "primereact/resources/themes/fluent-light/theme.css";  //theme
import "primereact/resources/themes/lara-light-blue/theme.css";  
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import PrimeReact from 'primereact/api';
import './index.css'
import Root from './routes'

PrimeReact.ripple = true;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
