import React, { useState } from 'react'
import Sidebar from '../components/pages/Sidebar/view';
import Header from '../components/pages/Header/view';
import { _setObject } from '../components/app/action';
import { useStateValue } from '../context';


const AppLayout = ({ children }) => {
    const [{app}, dispatch] = useStateValue()
    const { toggleState = true  } = app || {};
    console.log("toggleState on lay",toggleState);
  const [classNames, setClassNames] = useState('left_sidebar_show');

  const handleClick = () => {
      if (toggleState) {
          setClassNames('left_sidebar_hide ');
        } else {
          setClassNames('left_sidebar_show ');
        }
        dispatch(_setObject({ toggleState: !toggleState }));
    };
  
    return (
        <div className='layout-wrapper layout-static layout-theme-light' >
        <div className="layout-main-container">
            <div className="grid layout-main">
                <div className="col-12 md-sidebar">
                    <Sidebar toggleState={toggleState}/>
                </div>
               {toggleState ?  <>
                
                <div className={classNames + "col-12 md:col-10 mobSize responsiveSize"}> 
                <Header handleClick={handleClick}/>
                {children}</div>
               </>
             :
             <>
                <div className="col-12 md:col-12 col_12_mt">
                <Header handleClick={handleClick}/>
                    {children}
                </div>
                </>}
            </div>
        </div>
    </div>
    
    );
  };

  export default AppLayout;
