import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useStateValue } from '../../../context';
import useCommunications from './hook';
import { _setObject } from '../../app/action';
import { useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';




const Communications_List = (props) => {
    const [{ app }, dispatch] = useStateValue();
    const toast = useRef(null);
    const { Properties_List = [], Communications_List = [], Property = '', sentEmailSmsResponse = [], dialog = false, DialogMsg = {} } = app || {};
    const [{ CommunicationsListHandler, sendEmailSmsHandler }, { listLoding }] = useCommunications();
    const [selectedEmailRows, setSelectedEmailRows] = useState([]);
    const [selectedSMSRows, setSelectedSMSRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [EmailCheckbox, setEmailCheckbox] = useState(false);
    const [SMSCheckbox, setSMSCheckbox] = useState(false);
    const [selectedCommunications, setselectedCommunications] = useState([]);

    useEffect(() => {
        if ((Properties_List.length > 0 && Property.propertyId) || (Communications_List.length === 0)) {
            CommunicationsListHandler(Property.propertyId);
            dispatch(_setObject({ FromDate: null, ToDate: null, Checkins_FilterList: [] }));
            setSelectedEmailRows([]);
            setSelectedSMSRows([]);
        }
    }, [Property])
  

    const refreshCommunications = () => {
        if (Property.propertyId) {
            CommunicationsListHandler(Property.propertyId);
            setSelectedEmailRows([]);
            setSelectedSMSRows([]);
            setSMSCheckbox(false);
            setEmailCheckbox(false);
            setselectedCommunications([]);
        }
    };

    useEffect(() => {
        if (toast.current && dialog) {
            toast.current.show({
                severity: DialogMsg.success ? 'success' : DialogMsg.info ? 'info' : DialogMsg.error ? 'error' : DialogMsg.warning ? 'warn' : 'success',
                summary: DialogMsg.summary,
                detail: DialogMsg.detail || '',
                life: 3000
            });
            const timeoutId = setTimeout(() => {
                if (toast.current) {
                    toast.current.clear();
                    dispatch(_setObject({ dialog: false, DialogMsg: {} }));
                }
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [DialogMsg]);


    const serialNumbers = Array.from({ length: Communications_List.length }, (_, index) => index + 1);
    let indexedCommunicationsList = Communications_List.map((item, index) => ({
        ...item,
        serialNumber: serialNumbers[index]
    })) || [];

    // indexedCommunicationsList = indexedCommunicationsList.map((item) => {
    //     // Check if the current item's email matches the one you want to update
    //     // if (item.pmsReservationId === "622729") {
    //     //     // If it matches, update the email to an empty string
    //     //     return {
    //     //         ...item,
    //     //         // roomName: "",
    //     //     };
    //     // }
    //     if (item.email === "haresh.boteju@gmail.com") {
    //         // If it matches, update the email to an empty string
    //         return {
    //             ...item,
    //             isEmailSuccess: false,
    //             isSmsSuccess: false
    //         };
    //     }
    //     // If the email doesn't match, return the original item
    //     return item;
    // });
    // console.log("indexedCommunicationsList", indexedCommunicationsList)


    indexedCommunicationsList = indexedCommunicationsList.map((communications) => {
          const matchingReservation = sentEmailSmsResponse?.find(
            (communicationsData) => communicationsData.reservationId === communications.reservationId
        );
          
    
        if (matchingReservation) {
            
            return {
                ...communications,
                isEmailSuccess: matchingReservation.isEmailSuccess,
                isSmsSuccess: matchingReservation.isSmsSuccess
            };
        }
        return communications;
    });
    
    useEffect(() => {
        if(sentEmailSmsResponse.length > 0){
            dispatch(_setObject({ Communications_List: indexedCommunicationsList }));
            setSelectedEmailRows([]);
            setSelectedSMSRows([]);
        }
    }, [sentEmailSmsResponse])



    const EmailCheckboxChange = (checked) => {
        setEmailCheckbox(checked);
        let updatedEmailRows = [];
        if (checked) {
            updatedEmailRows = indexedCommunicationsList.filter(item => item.email !== '' && item.isEmailSuccess === false);
        }
        setselectedCommunications(updatedEmailRows);
        setSelectedEmailRows(updatedEmailRows);
    };

    const SMSCheckboxChange = (checked) => {
        setSMSCheckbox(checked);
        let updatedSMSRows = [];
        if (checked) {
            updatedSMSRows = indexedCommunicationsList.filter(item => item.mobile !== '' && item.isSmsSuccess === false);
        }
        setselectedCommunications(updatedSMSRows);
        setSelectedSMSRows(updatedSMSRows);
    };

    const isValidEmail = email => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex?.test(email);
    };

    const isValidAustralianPhoneNumber = phoneNumber => {
        // Remove all non-digit characters, including spaces, for standardization
        const cleanedNumber = phoneNumber?.replace(/\D/g, '');
      
        // Australian Mobile Numbers
        const mobileRegex = /^(?:614|\+?61?4|00614|04)\d{8}$/;
        if (mobileRegex?.test(cleanedNumber)) {
            console.log("Australian Mobile Numbers",phoneNumber )
          return true;
        }
      
        // Australian Landline Numbers
        const landlineRegex = /^(?:6187|6173|61261|61262|6138|6139|6186|61892|61893|61894|61106|61112|6113|6116|6118|6127|6128|6129)\d+$/;
        if (landlineRegex?.test(cleanedNumber)) {
            console.log("Australian Landline Numbers", phoneNumber)
          return true;
        }
      
        // Basic International Number (excluding Australian)
        // This is a very rudimentary check for international numbers
        const internationalRegex = /^\+?[1-9]\d{1,14}$/;
        if (internationalRegex?.test(cleanedNumber)) {
          return false;
        }
      
        return false;
      };

    const handleCheckboxChange = (type, rowData) => {
        const selectedRows = type === 'Email' ? selectedEmailRows : selectedSMSRows;
        const isSelected = selectedRows.some((row) => row.reservationId === rowData.reservationId);
        if (isSelected) {
            const updatedRows = selectedRows.filter((row) => row.reservationId !== rowData.reservationId);
            type === 'Email' ? setSelectedEmailRows(updatedRows) : setSelectedSMSRows(updatedRows);
        } else {
            const updatedRows = [...selectedRows, rowData];
            type === 'Email' ? setSelectedEmailRows(updatedRows) : setSelectedSMSRows(updatedRows);
        }
    };

    const handleSend = () => {
        const filteredSelectedRowsData = indexedCommunicationsList.filter(rowData =>
            selectedEmailRows.some(row => row.reservationId === rowData.reservationId) ||
            selectedSMSRows.some(row => row.reservationId === rowData.reservationId)
        )
            .map(rowData => ({
                reservationId: rowData.reservationId,
                email: selectedEmailRows.length > 0 && !rowData.isEmailSuccess ? selectedEmailRows.some(row => row.reservationId === rowData.reservationId) : selectedEmailRows.length === 0 && !rowData.isEmailSuccess ? false : true,
                sms: selectedSMSRows.length > 0 && !rowData.isSmsSuccess ? selectedSMSRows.some(row => row.reservationId === rowData.reservationId) : selectedSMSRows.length === 0 && !rowData.isSmsSuccess ? false : true,
                pmsReservationId: rowData.pmsReservationId
            }));
        if(filteredSelectedRowsData.length === 0){
            dispatch(_setObject({ dialog: true, DialogMsg: { warning: 'warning', summary: 'Reservation Must Be Selected', detail: '' } }));
        }else{
            const id = Property?.propertyId;
            sendEmailSmsHandler(id, filteredSelectedRowsData);
        }
    };

    const columns = [
        { field: 'serialNumber', header: 'ID', headerStyle: { minWidth: '50px' } },
        { field: 'pmsReservationId', header: 'Reservation ID', headerStyle: { minWidth: '130px' } },
        {
            field: 'Guestname', header: 'Guest Name', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return <span>{rowData.firstName + ' ' + rowData.lastName}</span>
            }
        },
        {
            field: 'CheckIn', header: 'Check-In', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                var dateParts = rowData.checkinDate.slice(0, 10).split("-");
                var rearrangedDate = dateParts?.reverse().join("-");
                return <span>{rearrangedDate}</span>
            }
        },
        {
            field: 'email', header: 'Email Address', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <div className='status text-left' key={rowData.pmsReservationId}>
                        {rowData.email}
                    </div>
                );
            }
        },
        {
            field: 'email',
            header: (
                <div className='checkbox-container flex align-items-center'>
                    {'Email'}
                    <Checkbox
                        onChange={(e) => EmailCheckboxChange(e.checked)}
                        checked={EmailCheckbox}
                    />
                </div>
            ),
            headerStyle: { minWidth: '100px' },
            body: (rowData) => {
                let shouldShowCheckbox;
                shouldShowCheckbox = (!rowData.isEmailSuccess || rowData.isEmailSuccess !== true || rowData.isEmailSuccess === 'False');
                shouldShowCheckbox = rowData.email === "" ? false : shouldShowCheckbox;
                shouldShowCheckbox = isValidEmail(rowData.email) ? shouldShowCheckbox : false;
                return shouldShowCheckbox ? (
                    <div className='action_btns'>
                        <Checkbox
                            onChange={(e) => { handleCheckboxChange('Email', rowData); setEmailCheckbox(false) }}
                            checked={selectedEmailRows.some(row => row.reservationId === rowData.reservationId)}
                        />
                    </div>
                ) : null;
            },
        },
        {
            field: 'email', header: 'Email Status', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <div className='status text-left' key={rowData.pmsReservationId}>
                        <div className={`status text-xs mx-0 ${(!rowData.isEmailSuccess || rowData.isEmailSuccess !== true || rowData.isEmailSuccess === 'False') ? 'not-submitted' : 'submitted'}`}>
                            {(!rowData.isEmailSuccess || rowData.isEmailSuccess != true || rowData.isEmailSuccess == 'False') ? 'Not Sent' : 'Sent'}
                        </div>
                    </div>
                );
            }
        },
        {
            field: 'mobile', header: 'Mobile', headerStyle: { minWidth: '150px' }, body: (rowData) => {
                return (
                    <span className='status text-left' key={rowData.pmsReservationId}>
                        {rowData.mobile}
                    </span>
                );
            }
        },
        {
            field: 'sms',
            header: (
                <div className='checkbox-container flex align-items-center'>
                    {' SMS'}
                    <Checkbox
                        onChange={(e) => SMSCheckboxChange(e.checked)}
                        checked={SMSCheckbox}
                    />
                </div>
            ),
            // header: 'SMS',
            headerStyle: { minWidth: '100px' },
            body: (rowData) => {
                let shouldShowCheckbox;
                shouldShowCheckbox = (!rowData.isSmsSuccess || rowData.isSmsSuccess !== true || rowData.isSmsSuccess === 'False');
                shouldShowCheckbox = rowData.mobile === "" ? false : shouldShowCheckbox;
                shouldShowCheckbox = isValidAustralianPhoneNumber(rowData.mobile) ? shouldShowCheckbox : false;
                return shouldShowCheckbox ? (
                    <div className='action_btns' key={rowData.pmsReservationId}>
                        <Checkbox
                            onChange={(e) => { handleCheckboxChange('SMS', rowData); setSMSCheckbox(false) }}
                            checked={selectedSMSRows.some(row => row.reservationId === rowData.reservationId)}
                        />
                    </div>
                ) : null;
            }
        },
        {
            field: 'mobile', header: 'SMS Status', headerStyle: { minWidth: '150px' }, body: (rowData) => {
                return (
                    <span className='status text-left'>
                        <div key={rowData.pmsReservationId} className={`status text-xs mx-0 ${(!rowData.isSmsSuccess || rowData.isSmsSuccess !== true || rowData.isSmsSuccess === 'False') ? 'not-submitted' : 'submitted'}`}>
                            {(!rowData.isSmsSuccess || rowData.isSmsSuccess != true || rowData.isSmsSuccess == 'False') ? 'Not Sent' : 'Sent'}
                        </div>
                    </span>
                );
            }
        },
    ];


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search by Res. ID..." />
            </span>
            <div className='view_box'>
                <Button className='add_btn mr-2' label="Send" tooltip="2024-01-15Send" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={handleSend} />
            </div>
        </div>
    );


    return (
        <div className='container_box'>
            <Toast ref={toast} />
            {listLoding &&
                (<div className="loading-spinner-overlay-login">
                    <div class="clock"></div>
                </div>)
            }
            <div className="grid card">
                <div className='col-12 page_title'>
                    <h5 className='mb-0 mt-2'>Communications</h5>
                    <Button className='reload_btn' icon="pi pi-replay"  rounded="true" raised="true" text="true" aria-label="Refresh" tooltip="Refresh" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={refreshCommunications} />

                </div>
                <div className="col-12 md:col-12">
                    <DataTable value={indexedCommunicationsList}
                        showGridlines header={header}
                        globalFilterFields={['pmsReservationId']}
                        paginator rows={10} rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                        globalFilter={globalFilter} emptyMessage="No records found"
                        selection={selectedCommunications}
                        sortField={['pmsReservationId']} sortOrder={1}
                        rowKey="uniqueKey"
                        onSelectionChange={(e) => setselectedCommunications(e.value)}>
                        {columns.map((column) => (
                            <Column
                                key={column.field}
                                field={column.field}
                                header={column.header}
                                headerStyle={column.headerStyle}
                                body={column.body}
                            />
                        ))}
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default Communications_List
