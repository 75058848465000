import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { StateProvider } from '../context';

import { INITIAL_STATE as APP_INITIAL_STATE } from '../components/app/reducer'

import reducers from "../reducers";
import Header from '../components/pages/Header/view';
import routes from './routes.js';
import Sidebar from '../components/pages/Sidebar/view';

const Root = _ => {
    const initialState = {
        // auth: AUTH_INITIAL_STATE,
        app: APP_INITIAL_STATE
    }
  
    return (
        <StateProvider initialState={initialState} reducer={reducers}>
            <Router>
                {routes}
            </Router>
        </StateProvider >
    )
};

export default Root