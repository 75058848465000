import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'
import { useState } from 'react'

const useCommunications = () => {
    const [{app}, dispatch] = useStateValue()
    const {  accessToken = '' } = app || {};
    const [ listLoding, setListLoding ] = useState(false)


    const CommunicationsListHandler = async (params) => {
        setListLoding(true)
        const headers={
            'Accept': '*/*',
            'Authorization': `Bearer ${accessToken}`,
          }
          const requestOptionsData = {
            method: 'GET',
            headers:headers
          };
        dispatch(_setObject({ Communications_List: [] }))
        const response = await relayService({ url: '/module/moduleList', method: 'POST', data:{'module':`communication/arriving/${params}`, 'requestOptionsData': requestOptionsData} })
        if (response?.data?.result) {
            setListLoding(false)
            dispatch(_setObject({ Communications_List: response?.data?.result?.sort((a,b)=> a.reservation?.pmsReservationId - b.reservation?.pmsReservationId) }))
        }
    }
    
    const sendEmailSmsHandler = async (id, params) => {
      setListLoding(true); 
      const pmsReservationIds = params.map(row => row.pmsReservationId);
        const headers={
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
          const requestOptionsData = {
            method: 'POST',
            headers:headers,
            body:JSON.stringify(params)
          };
            dispatch(_setObject({ sentEmailSmsResponse: [] }))
            const response = await relayService({ url: '/module/moduleList', method: 'POST', data: { 'module': `communication/send/${id}`, 'requestOptionsData': requestOptionsData} })
           if (response?.data) {
                setListLoding(false);
                dispatch(_setObject({ dialog : true, DialogMsg: { success: 'success', summary: 'Email/SMS Sent Successfully', detail: `For Reservation ${pmsReservationIds}` } }));
                dispatch(_setObject({ sentEmailSmsResponse: response?.data?.result }))
            }
    }

    return [{ CommunicationsListHandler, sendEmailSmsHandler },{listLoding}]
}

export default useCommunications
