import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'
import { useState } from 'react'

const useCheckins = () => {
  const [{ app }, dispatch] = useStateValue()
  const { accessToken = '' } = app || {};
  const [listLoding, setListLoding] = useState(false)

  const CheckinsListHandler = async (params) => {
    setListLoding(true);
    const headers = {
      'Accept': '*/*',
      'Authorization': `Bearer ${accessToken}`,
    }
    const requestOptionsData = {
      method: 'GET',
      headers: headers
    };
    dispatch(_setObject({ Checkins_List: [] }))
    const response = await relayService({ url: '/module/moduleList', method: 'POST', data: { 'module': `reservation/checkins/${params}`, 'requestOptionsData': requestOptionsData } })
    if (response?.data?.result) {
      setListLoding(false);
      dispatch(_setObject({ Checkins_List: response?.data?.result?.sort((a, b) => a.reservation?.pmsReservationId - b.reservation?.pmsReservationId) }))
    }
  }

  const CheckinsListFilter = async (propertyID, FromDate, ToDate) => {
    setListLoding(true)
    const headers = {
      'Accept': '*/*',
      'Authorization': `Bearer ${accessToken}`,
    }
    const requestOptionsData = {
      method: 'GET',
      headers: headers
    };
    function formatDateToUTC(date) {
      const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      const formattedDate = utcDate.toISOString().slice(0, 19) + 'Z';
      return formattedDate;
    }

    const formattedFromDate = formatDateToUTC(FromDate);
    const formattedToDate = formatDateToUTC(ToDate);

    const response = await relayService({ url: '/module/moduleList', method: 'POST', data: { 'module': `reservation/checkins/${propertyID}?startDate=${formattedFromDate}&endDate=${formattedToDate}`, 'requestOptionsData': requestOptionsData } })
    if (response?.data?.result) {
      setListLoding(false)
      dispatch(_setObject({ Checkins_FilterList: response?.data?.result?.sort((a, b) => a.reservation?.pmsReservationId - b.reservation?.pmsReservationId) }))
    }
  }

  return [{ CheckinsListHandler, CheckinsListFilter, setListLoding }, { listLoding }]
}

export default useCheckins
