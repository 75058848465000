import React, { useState, useEffect } from 'react';
import Brand_logo from '../../../assets/images/24SevenCheckIn.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';

const Sidebar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [menuItems, setMenuItems] = useState([
        { label: 'Arrivals', icon: 'pi pi-fw pi-home', route: '/arrivals', className: '' },
        { label: 'Communications', icon: 'pi pi-fw pi-comments', route: '/communications', className: '' },
        { label: 'Check-Ins', icon: 'pi pi-fw pi-reply', route: '/check-ins', className: '' },
        { label: 'Settings', icon: 'pi pi-fw pi-sliders-h', route: '/settings', className: '' },
        { label: 'Support', icon: 'pi pi-fw pi-phone', route: '/support', className: '' },
    ]);

    const [activeItem, setActiveItem] = useState('arrivals');

    useEffect(() => {
        const activeRoute = menuItems.find(item => location.pathname.startsWith(item.route));
        setMenuItems(prevMenuItems => {
          return prevMenuItems.map(item => ({
            ...item,
            className: activeRoute && activeRoute.route === item.route ? 'Active' : '',
          }));
        });
      }, [location.pathname]);

    const handleMenuClick = (item) => {
        navigate(item.route);
        setActiveItem(item.label);
    };

    return (
        <div className="sidebar-header">
            <div className="sidebar-toggle">
                {props.toggleState && (
                    <div className='Sidebar_left'>
                        <div className='text-center'>
                            <img src={Brand_logo} alt="24SevenCheckIn" height={80} className="p-2" />
                        </div>
                        <PanelMenu
                            model={menuItems.map(item => ({ ...item, command: () => handleMenuClick(item) }))}
                            activeItem={activeItem}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
