import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'
import { useState } from 'react'

const useArrivals = () => {
    const [{ app }, dispatch] = useStateValue()
    const { Property = '', accessToken = '' } = app || {};
    const [ listLoding, setListLoding ] = useState(false)


    const ArrivalsListHandler = async (params) => {
      const headers={
        'Accept': '*/*',
        'Authorization': `Bearer ${accessToken}`,
      }
      const requestOptionsData = {
        method: 'GET',
        headers:headers
      };
        setListLoding(true)
          dispatch(_setObject({ Arrivals_List: [] }))
          const response = await relayService({ url: '/module/moduleList', method: 'POST', data: { 'module': `Reservation/arriving/${params}`, 'requestOptionsData': requestOptionsData} })
            if (response?.data?.result) {
              setListLoding(false)
                dispatch(_setObject({ Arrivals_List: response?.data?.result?.sort((a,b)=> a.reservation?.pmsReservationId - b.reservation?.pmsReservationId) }))
            }

    }

    const keyGenerateHandler = async (id, params, pmsReservationId) => {
        setListLoding(true)
        const headers={
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
          const requestOptionsData = {
            method: 'POST',
            headers:headers,
            body:JSON.stringify(params)
          };
            dispatch(_setObject({ keyGenerateData: [] }))
            const response = await relayService({ url: '/module/moduleList', method: 'POST', data: { 'module': `KeyReservation/createkeys/${id}`, 'requestOptionsData': requestOptionsData} })
            if (response?.data?.result) {
                setListLoding(false)
                dispatch(_setObject({ keyGenerateData: response?.data?.result }))
                dispatch(_setObject({ dialog : true, DialogMsg: { success: 'success', summary: 'Key Generated Successfully', detail: `For Reservation ${pmsReservationId}` } }));
            }
    }

    const  imageUrlToBase64 = async (url) => {
        const response = await relayService({ url: '/module/urlToBase64', method: 'POST', data: { 'url': url} })
        return response?.data?.base64Image;
  }

    return [{ ArrivalsListHandler, keyGenerateHandler, imageUrlToBase64 }, {listLoding}]
}

export default useArrivals
