import { useLocation, useNavigate } from 'react-router-dom'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'
import { useState } from 'react'

const useAuth = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [{ app }, dispatch] = useStateValue()
    const { accessToken = '' } = app || {};
    const [ loginLoding, setLoginLoding ] = useState(false)
    const [displayBasic, setDisplayBasic] = useState(false);

    const login = async (params) => {
        setLoginLoding(true)
        const requestOptionsData = {
            method: 'POST',
            headers: {
              'Accept': '*/*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "userId": params.username,
              "password": params.password
            })
          };
        const response = await relayService({ url: '/module/moduleList', method: 'POST', data:{'module':`Auth/token`,  'requestOptionsData': requestOptionsData, 'username':`${params.username}`, 'password':`${params.password}`
    }})
        if (response?.data?.success) {
            setLoginLoding(false)
            localStorage.setItem("24access_token", response?.data?.result?.token);
            dispatch(_setObject({ accessToken: response?.data?.result?.token }))
            navigate('/arrivals')
        }
        else{
            setLoginLoding(false)
            setDisplayBasic(true)
        }
    }

    const logout = async (params) => {
        dispatch(_setObject({ accessToken: '' }))
        localStorage.removeItem("24access_token");
        navigate('/')
        window.location.reload();
    }

    return [{ login, logout }, { loginLoding, displayBasic, accessToken }]
}

export default useAuth
