import { useNavigate } from 'react-router-dom'
import { useStateValue } from '../../../context'
import { _setObject } from '../../app/action'
import relayService from '../../axios/hook'

const useHeader = () => {
    const [{ app }, dispatch] = useStateValue()
    const { accessToken = '' } = app || {};
    
    const PropertiesList = async () => {
        const headers = { 'Accept': '*/*', 'Authorization': `Bearer ${accessToken}` };
        const requestOptions = { method: 'GET', headers: headers};
        try {
            const allowedPropertiesResponse = await relayService({ 
                url: '/module/moduleList',
                method: 'POST',
                data: { 'module': 'User/me', 'requestOptionsData': requestOptions }
            });
    
            const allowedProperties = allowedPropertiesResponse?.data?.result?.allowedProperties;
            if (allowedProperties && allowedProperties.length > 0) {
                const propertyId = allowedProperties[0].propertyId;
                const propertiesDataResponse = await relayService({
                    url: '/module/moduleList',
                    method: 'POST',
                    data: { 'module': `Property/${propertyId}`, 'requestOptionsData': requestOptions }
                });
    
                const propertyData = propertiesDataResponse?.data?.result;
                if (propertyData) {
                    dispatch(_setObject({ Properties_List: allowedProperties }));
                    dispatch(_setObject({ Property: propertyData }));
                }
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const setSelectedProperty = (Property) => {
        dispatch(_setObject({ Property: Property }))
    }

    return [{ PropertiesList, setSelectedProperty },{}]
}

export default useHeader
