import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useStateValue } from '../../../context';
import useArrivals from './hook';
import html2pdf from 'html2pdf.js';
import { _setObject } from '../../app/action';
import { Toast } from 'primereact/toast';


const Arrivals_list = (props) => {
    const dataTableRef = useRef(null);
    const toast = useRef(null);
    const [{ app }, dispatch] = useStateValue();
    const { Properties_List = [], Arrivals_List = [], Property = '', keyGenerateData = [], dialog = false, DialogMsg = {} } = app || {};
    const [{ ArrivalsListHandler, keyGenerateHandler, imageUrlToBase64 }, { listLoding }] = useArrivals();
    const [selectedRows, setSelectedRows] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [keyGeneration, setKeyGeneration] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [selectedArrivals, setSelectedArrivals] = useState([]);

    useEffect(() => {
        if (Properties_List.length > 0 && Property.propertyId) {
            ArrivalsListHandler(Property.propertyId);
            dispatch(_setObject({ FromDate: null, ToDate: null, Checkins_FilterList: [] }));
            setSelectedRows([]);
        }
    }, [Property]);

    const refreshArrivals = () => {
        if (Property.propertyId) {
            ArrivalsListHandler(Property.propertyId);
            setSelectedArrivals([]);
        }
    };


    useEffect(() => {
        if (toast.current && dialog) {
            toast.current.show({
                severity: DialogMsg.success ? 'success' : DialogMsg.info ? 'info' : DialogMsg.error ? 'error' : DialogMsg.warning ? 'warn' : 'success',
                summary: DialogMsg.summary,
                detail: DialogMsg.detail || '',
                life: 3000
            });
            const timeoutId = setTimeout(() => {
                if (toast.current) {
                    toast.current.clear();
                    dispatch(_setObject({ dialog: false, DialogMsg: {} }));
                }
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [DialogMsg]);

    const handleHeaderCheckboxChange = (checked) => {
        setHeaderCheckbox(checked);
        setSelectedArrivals(checked ? indexedArrivalsList : []);
    };

    const handleCheckboxChange = (rowData) => {
        const isSelected = selectedArrivals.some((row) => row.reservationId === rowData.reservationId);

        if (isSelected) {
            setSelectedArrivals(selectedArrivals.filter((row) => row.reservationId !== rowData.reservationId));
        } else {
            setSelectedArrivals([...selectedArrivals, rowData]);
        }
    };

    const handleProcess = () => {
        const selectedReservationIds = selectedArrivals.map(row => row.reservationId);
        const selectedpmsReservationIds = selectedArrivals.map(row => row.pmsReservationId);
        if (selectedArrivals.length === 0) {
            dispatch(_setObject({ dialog: true, DialogMsg: { warning: 'warning', summary: 'Reservation Must Be Selected', detail: '' } }));
        } else {
            setKeyGeneration(true);
            const id = Property.propertyId
            keyGenerateHandler(id, selectedReservationIds, selectedpmsReservationIds)
        }
    };


    // const serialNumbers = Array.from({ length: Arrivals_List?.length }, (_, index) => index + 1);
    Arrivals_List.sort((a, b) => a.pmsReservationId - b.pmsReservationId);
    // console.log("serialNumbers",serialNumbers)


    let indexedArrivalsList = Arrivals_List.length > 0 && Arrivals_List.map((item, index) => ({
        ...item,
        serialNumber: index + 1
    })) || [];



    indexedArrivalsList = indexedArrivalsList?.map((arrival) => {
        const matchingReservation = keyGenerateData?.find(
            (keyData) => keyData.reservationId === arrival.reservationId
        );
        if (matchingReservation) {
            const updatedArrival = {
                ...arrival,
                keyPinCode: matchingReservation.keyCode
            }
            return updatedArrival;
        }
        return arrival;
    });

    useEffect(() => {
        if (keyGenerateData.length > 0) {
            dispatch(_setObject({ Arrivals_List: indexedArrivalsList }));
            setSelectedArrivals([]);
        }
    }, [keyGenerateData])
    // console.log("indexedArrivalsList", indexedArrivalsList)


    // indexedArrivalsList = indexedArrivalsList.map((item) => {
    //     // Check if the current item's email matches the one you want to update
    //     if (item.pmsReservationId === "704939") {
    //         // If it matches, update the email to an empty string
    //         return {
    //             ...item,
    //             keyPinCode: "coidsncij",
    //         };
    //     }
    //     // if (["626999","703934","703935","666040", "666041", "666042", "666043", "666044", "666045", "666046", "666047", "666048", "666049", "666051", "626999","666051",
    //     // "666064","666065","666066","666067","666068","666069","666070",].includes(item.pmsReservationId)) {
    //     //     // If it matches, update the email to an empty string
    //     //     return {
    //     //         ...item,
    //     //         keyPinCode: "csdcds"
    //     //         // keyPinCode: ""
    //     //     };
    //     // }
    //     if (item.keyPinCode === "") {
    //         // If it matches, update the email to an empty string
    //         return {
    //             ...item,
    //             keyPinCode: "csdcds"
    //             // keyPinCode: ""
    //         };
    //     }
    //     // If the email doesn't match, return the original item
    //     return item;
    // });
    // console.log("indexedArrivalsList", indexedArrivalsList)

    const cols = [
        {
            field: 'id',
            header: '#',
            headerStyle: { minWidth: '50px' },
        },
        { field: 'pmsReservationId', header: 'RES. #', headerStyle: { minWidth: '130px' } },
        {
            field: 'guest', header: 'GUEST NAME', headerStyle: { minWidth: '130px' }
        },
        { field: 'roomName', header: 'ROOM #', headerStyle: { minWidth: '130px' }, },
        {
            field: 'keyPinCode', header: 'KEY CODE', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        {rowData.keyPinCode == "" ?
                            <div className='Keys'>{'-'}</div> :
                            <div className='Keys'>{rowData.keyPinCode}</div>
                        }
                    </>
                );
            }
        }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportPdf = async () => {
        const header = exportColumns.map((col) => col.title);
        let filteredArrivalsList = indexedArrivalsList.filter((row) => row.keyPinCode !== '');
        filteredArrivalsList = filteredArrivalsList.map((row, index) => {
            row.id = index + 1;
            return row;
        });
        const imageBase64 = await imageUrlToBase64(`${Property?.propertyLogo}`);
        var dateParts = filteredArrivalsList[0]?.checkinDate.slice(0, 10).split("-") || indexedArrivalsList[0]?.checkinDate.slice(0, 10).split("-");
        var expectedDate = dateParts?.reverse().join("-");
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const todayDate = `${dd}-${mm}-${yyyy}`;
        let pdfHeader = '';
        let pdfFooter = '';
        let pdfContent = '';
        let pdfWholeContent = '';

        pdfHeader += `<div class="header">
        <div class="logo-container">
    
        <img src="data:image/png;base64,${imageBase64}" alt="${Property?.propertyName}" class="logo">
         <div class="header-text">
             <h2>${Property?.propertyName}</h2>
              <p>${Property?.address}</p>
         </div>
        </div>
        </div>`;

        pdfContent += `<div class="pdfContent">
        <h6 class="arrival-title" >Expected Arrivals for ${expectedDate == 'Invalid Date' ? todayDate : expectedDate}</h6>

        <table class="table">

            <tr class="table-header">
                ${header.map((col) => `<th class="table-cell">${col}</th>`).join('')}
            </tr>
            ${filteredArrivalsList.map((row, index) => {
                // Add a page break after every 25 records
                // console.log("indexindexindex", index);
                // console.log("row", row); // Add this line for debugging
                // console.log("exportColumns", exportColumns); // Add this line for debugging
            
                const tableRow = exportColumns.map((col) => {
                    switch (col.dataKey) {
                        case 'guest':
                            return `<td class="table-cell">${row.guest.firstName} ${row.guest.lastName}</td>`;
                        default:
                            return `<td class="table-cell">${row[col.dataKey]}</td>`;
                    }
                }).join('');
            
                if (index > 0 && index % 25 === 0) {
                    return `
                    </table>
                    </div><div class="page-break"></div>
                    <div class="pdfContent">
                    <br>
                    ${pdfHeader}
                        <h6 class="arrival-title" >Expected Arrivals for ${expectedDate == 'Invalid Date' ? todayDate : expectedDate}</h6>
                        <table class="table">
                            <tr class="table-header">
                                ${header.map((col) => `<th class="table-cell">${col}</th>`).join('')}
                                </tr>
                            ${tableRow}`;
                }
            
                return `<tr>${tableRow}</tr>`;
            }).join('')}
            </table>
    </div>
    `;

        pdfFooter += `<div class="footer">Designed and developed by H2TechLabs</div>`;
    

        pdfWholeContent = `
        <html>
        <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Expected Arrivals</title>
            <style>
              .PageBody {
                font-family: Arial, sans-serif;
                position: relative;
              }
              .logo-container {
                display: flex;
                margin-bottom: 16px;
                align-items: center;
                gap: 16px;
              }
              .logo {
                    width: 98px;
                    height: 54PX
              }
             
              .header-text h2 {
                text-transform: uppercase;
                font-size: 22px;
                margin: 0px;
                color: black;
                font-weight: bold;
              }
              .header-text p {
                margin: 0px;
                font-size: 13px;
                color: black;
              }
              .arrival-title {
                margin-bottom: 10px;
                color: black;
                font-weight: bold;
              }
              .table {
                width: 100%;
                border-collapse: collapse;
              }
              .table-cell,
              .table-header {
                padding: 10px;
                text-align: left;
                font-weight: 400;
              }
              .table-cell, .table-header {
                padding: 10px;
                text-align: left;
                font-weight: 400;
                font-size: 14px;
                color: black;
            }
              .table-header {
                background-color: #e7e7e7 !important;
                font-weight: bold;
                color: black;
              }
              tr:nth-child(odd) {
                background-color: #f9f9f9;
            }
            
            .footer{
                width: 100%;
                text-align: center;
                font-size: 14px;
                color: black;
                margin-top: 32px;
                position: absolute;
                bottom: 0px
            }
            </style>
        </head>
        <body>
            <div class="PageBody">
                ${pdfHeader}
                ${pdfContent}
                     <br>
                     <br>
                     <br>
                    </div> 
                    </body>
                    </html>
        `;

        // console.log("pdfWholeContent",pdfWholeContent)

        const options = {
            margin: 10,
            filename: 'Arrival_list.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { before: '.page-break', avoid: 'table' }
        };
        // const pdfObj = await html2pdf().set(options).from(pdfWholeContent).outputPdf().get('pdf');
        const pdfObj = await html2pdf().from(pdfWholeContent).set(options).outputPdf()
            .get('pdf').then(function (pdf) {
              var totalPages = pdf.internal.getNumberOfPages();
    
              for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(12);
                pdf.setTextColor(0, 0, 0);
                pdf.text('Designed and developed by H2TechLabs', 68, pdf.internal.pageSize.getHeight() - 5);
              } 
        }).output('dataurlnewwindow', '_blank');
        // const pdfBlob = pdfObj.output('blob');
        // const pdfUrl = URL.createObjectURL(pdfBlob);
        // window.open(pdfUrl, '_blank');
    };


    const columns = [
        {
            field: 'serialNumber',
            header: 'ID',
            headerStyle: { minWidth: '50px' },
        },
        { field: 'pmsReservationId', header: 'Reservation ID', headerStyle: { minWidth: '130px' } },
        {
            field: 'guest', header: 'Guest Name', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        <div className='Res'>{rowData.guest.firstName + ' ' + rowData.guest.lastName}</div>
                    </>
                );
            }
        },
        {
            field: 'checkinDate', header: 'Check-In', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                var dateParts = rowData.checkinDate.slice(0, 10).split("-");
                var rearrangedDate = dateParts?.reverse().join("-");
                return (
                    <>
                        <div className='Res'>{rearrangedDate}</div>
                    </>
                );
            }
        },
        {
            field: 'checkoutDate', header: 'Check-Out', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                var dateParts = rowData.checkoutDate.slice(0, 10).split("-");
                var rearrangedDate = dateParts?.reverse().join("-");
                return (
                    <>
                        <div className='Res'>{rearrangedDate}</div>
                    </>
                );
            }
        },
        { field: 'roomName', header: 'Room', headerStyle: { minWidth: '130px' }, },
        {
            field: 'Keys',
            header: (
                <div className="checkbox-container flex align-items-center">
                    <div className="title mr-2">Keys</div>
                    <Checkbox
                        onChange={(e) => handleHeaderCheckboxChange(e.checked)}
                        checked={headerCheckbox}
                    />
                </div>
            ),
            headerStyle: { minWidth: '100px' },
            body: (rowData) => {
                return (
                    <>
                        <div className='action_btns'>
                            {(rowData.keyPinCode === "" && rowData.roomName !== "") && (
                                <Checkbox
                                    onChange={() => { handleCheckboxChange(rowData); setHeaderCheckbox(false) }}
                                    checked={selectedArrivals.some((row) =>
                                        row.reservationId === rowData.reservationId
                                    )}
                                />
                            )}
                        </div>
                    </>
                );
            }
        },
        {
            field: 'keyPinCode', header: 'Key Code', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        {rowData.keyPinCode == "" ?
                            <div className='Keys'>{'-'}</div> :
                            <div className='Keys'>{rowData.keyPinCode}</div>
                        }
                    </>
                );
            }
        },
        {
            field: 'status', header: 'Status', headerStyle: { minWidth: '150px' }, body: (rowData) => {
                return (
                    <div className='status'>{rowData.keyPinCode == "" ? (
                        <span className="not-submitted">Not Submitted</span>
                    ) : (
                        <span className="processed">Processed</span>
                    )}</div>
                );
            }
        },

    ];

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search by Res. ID..." />
            </span>
            <div className='view_box'>
                <Button className='add_btn mr-2' label="Process" tooltip="Generate Keys" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={handleProcess} />
                {keyGeneration && !keyGenerateData && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}
            </div>
        </div>
    );

    return (
        <div className='container_box'>
            <Toast ref={toast} />
            {listLoding &&
                (<div className="loading-spinner-overlay-login">
                    <div className="clock"></div>
                </div>)
            }
            <div className="grid card">
                <div className='col-12 page_title'>
                    <h5 className='mb-0 mt-2'>Arrivals</h5>
                    <div>
                        <Button className='print_btn' tooltip="Print" icon="pi pi-print"
                            tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={exportPdf} />
                        <Button className='reload_btn' rounded="true" raised="true" text="true" icon="pi pi-replay"
                            aria-label="Refresh" tooltip="Refresh" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
                            onClick={refreshArrivals} />
                    </div>
                </div>
                <div className="col-12 md:col-12">
                    <DataTable
                        ref={dataTableRef}
                        globalFilterFields={['pmsReservationId']}
                        value={indexedArrivalsList} showGridlines header={header}
                        paginator rows={10} rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                        globalFilter={globalFilter} emptyMessage="No records found"
                        selection={selectedArrivals}
                        onSelectionChange={(e) => setSelectedArrivals(e.value)}
                        sortField={["pmsReservationId"]} sortOrder={1}
                    >
                        {columns.map((column) => (
                            <Column
                                key={column.field}
                                field={column.field}
                                header={column.header}
                                headerStyle={column.headerStyle}
                                body={column.body}
                                sortFunction={(data1, data2) => {
                                    const value1 = Number(data1.pmsReservationId);
                                    const value2 = Number(data2.pmsReservationId);
                                    return value1 - value2;
                                }}
                            />

                        ))}
                    </DataTable>
                </div>
            </div>
        </div>
    );
}

export default Arrivals_list
