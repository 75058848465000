import React, { useState, useEffect, useRef } from 'react';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { PanelMenu } from 'primereact/panelmenu';
import { Tooltip } from 'primereact/tooltip';
import useHeader from './hook';
import { useStateValue } from '../../../context';
import useAuth from '../auth/hook';
import { _setObject } from '../../app/action';






const Header = (props) => {
    const navigate = useNavigate();
    const [{ app }, dispatch] = useStateValue()
    const { Properties_List = [], Property = '', toggleState = false } = app || {};
    const [{ PropertiesList, setSelectedProperty, }, { }] = useHeader();
    const [{ logout, isLoggedIn }] = useAuth();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        if (Properties_List.length == 0) {
            PropertiesList();
        }
    }, [Properties_List])


    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }
    const notification = useRef(null);
    const op = useRef(null);

    const [activeItem, setActiveItem] = useState(null);

    const [menuItems, setMenuItems] = useState([
        {
            label: 'Arrivals',
            icon: 'pi pi-fw pi-home',
            command: (e) => {
                navigate('/arrivals');
                setActiveItem('arrivals');

                const newMenuItems = [...menuItems];
                newMenuItems[0].className = 'Active';
                newMenuItems[1].className = '';
                newMenuItems[2].className = '';
                newMenuItems[3].className = '';
                newMenuItems[4].className = '';

                setMenuItems(newMenuItems);
            },

        },
        {
            label: 'Communications',
            icon: 'pi pi-fw pi-comments',
            command: (e) => {
                navigate('/communications');
                setActiveItem('communications');

                const newMenuItems = [...menuItems];
                newMenuItems[0].className = '';
                newMenuItems[1].className = 'Active';
                newMenuItems[2].className = '';
                newMenuItems[3].className = '';
                newMenuItems[4].className = '';

                setMenuItems(newMenuItems);
            },

        },
        {
            label: 'Check-Ins',
            icon: 'pi pi-fw pi-reply',
            command: (e) => {
                navigate('/check-ins');
                setActiveItem('check-ins');

                const newMenuItems = [...menuItems];
                newMenuItems[0].className = '';
                newMenuItems[1].className = '';
                newMenuItems[2].className = 'Active';
                newMenuItems[3].className = '';
                newMenuItems[4].className = '';

                setMenuItems(newMenuItems);
            },

        },
        {
            label: 'Settings',
            icon: 'pi pi-fw pi-sliders-h',
            command: (e) => {
                navigate('/settings');
                setActiveItem('settings');

                const newMenuItems = [...menuItems];
                newMenuItems[0].className = '';
                newMenuItems[1].className = '';
                newMenuItems[2].className = '';
                newMenuItems[3].className = 'Active';
                newMenuItems[4].className = '';

                setMenuItems(newMenuItems);
            },

        },
        {
            label: 'Support',
            icon: 'pi pi-fw pi-phone',
            command: (e) => {
                navigate('/support');
                setActiveItem('support');

                const newMenuItems = [...menuItems];
                newMenuItems[0].className = '';
                newMenuItems[1].className = '';
                newMenuItems[2].className = '';
                newMenuItems[3].className = '';
                newMenuItems[4].className = 'Active';

                setMenuItems(newMenuItems);
            },

        },


    ]);


    const handleClick = () => {
        // setToggleState(!toggleState);
        dispatch(_setObject({ toggleState: !toggleState }));
        props.handleClick();
    };

    const [showContent, setShowContent] = useState(false);
    const toggleContent = () => {
        setShowContent(!showContent);
    };

    const start =
        <div className='header_start'>
            <div>
                <span>
                    <Button className='toggle_btn_top hide_btn' icon="pi pi-bars" tooltip="Window Size" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => props.handleClick()} />
                </span>
                <span className='sidebar_toggle'>
                    <Button className='toggle_btn_top' icon="pi pi-bars" onClick={toggleContent} />
                </span>
            </div>
            <div className='propertyName'>
                <span className=''>
                    {Property.propertyName}
                </span>
            </div>
        </div>;
    const end = <span className='function_btn_box'>
        {/* <span>
            <Button className='toggle_btn_top hide_btn' icon="pi pi-bars" tooltip="Window Size" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => props.handleClick()} />
        </span> */}
        {/* <span className='sidebar_toggle'>
            <Button className='toggle_btn_top' icon="pi pi-bars" onClick={toggleContent} />
        </span> */}
        {Properties_List.length > 1 && <span className="notification">
            <Tooltip target=".notification_icon" position="bottom" mouseTrack mouseTrackTop={15}>
                Filter
            </Tooltip>
            <i className="pi pi-filter notification_icon" p-overlay-badge onClick={(e) => notification.current.toggle(e)}></i>
            <OverlayPanel className='Filter_overlay' ref={notification} showCloseIcon>
                <div className='grid mt-0 mb-0'>
                    <div className='col-12'>
                        <Dropdown value={Property} onChange={(e) => { setSelectedProperty(e.value) }} options={Properties_List} optionLabel="propertyName" option showClear
                            placeholder="Select a Property" className="w-full md:w-14rem" />
                    </div>
                </div>
            </OverlayPanel>
        </span>}

        <span className="head-profile">
            <Button className='logout_btn' icon="pi pi-sign-out" tooltip="Logout" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={logout} />
        </span>
        {/* <SplitButton label="My Profile" icon="pi pi-user" model={items2}></SplitButton> */}
    </span>;

    return (
        <div className="col-12 md:col-12 Header_topbar">
            <Menubar model='' start={start} end={end} />
            {showContent && (
                <PanelMenu className='Sidebar_togle' model={menuItems} dismissable={false} />
            )}
        </div>
    );
}

export default Header;
