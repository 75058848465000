import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';




const Settings_list = (props) => {
    const [visible, setVisible] = useState(false);
    const [date, setDate] = useState(null);
    const [checked, setChecked] = useState(true);
    const [value, setValue] = useState('');

    const data = [{id:1, Res: '678425', Guestname: 'John Doe',status: 'Processed',CkeckIn: '20-03-2023', CheckOut: '20-03-2023', Room: 'AR 102 LB',Keys: '' ,KeyCode: '2908' },
    {id:2, Res: '678249', Guestname: 'Adom Angelis',status: 'Processed',CkeckIn: '20-03-2023', CheckOut: '20-03-2023', Room: 'AR 102 LB',Keys:'',KeyCode: '2908' },
    {id:3, Res: '678426', Guestname: 'Kaitlyn Delioglanis',status: 'Processed',CkeckIn: '20-03-2023', CheckOut: '20-03-2023', Room: 'AR 102 LB',Keys:'20-03-2023',KeyCode: '2908' },
    {id:4, Res: '678439', Guestname: 'John Doe',status: 'Processed',CkeckIn: '20-03-2023', CheckOut: '20-03-2023', Room: 'AR 102 LB',Keys:'20-03-2023',KeyCode: '' },
    {id:5, Res: '678249', Guestname: 'Adom Angelis',status: 'Processed',CkeckIn: '20-03-2023', CheckOut: '20-03-2023', Room: 'AR 102 LB',Keys:'20-03-2023',KeyCode: '' },]

    const [globalFilter, setGlobalFilter] = useState(null);

    const columns = [
        {field: 'id', header: 'id', headerStyle:{minWidth : '50px' },},
        {field: 'Res', header: 'Res',headerStyle: { minWidth: '130px' }},
        {field: 'Guestname', header: 'Guest Name',headerStyle: { minWidth: '130px' }},
        {field: 'CkeckIn', header: 'Ckeck-In',headerStyle: { minWidth: '130px' }},
        {field: 'CheckOut', header: 'Check-Out',headerStyle: { minWidth: '130px' }},
        {field: 'Room', header: 'Room',headerStyle: { minWidth: '130px'},},
        {field: 'Keys', header: 'Keys',headerStyle:{minWidth : '230px' }, body: (rowData) => {
            return (
                <>
                    <div className='action_btns'>
                    <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                    </div>
                </>
            );
        } },
        {field: 'status', header: 'Status',headerStyle: { minWidth: '130px' },body: (rowData) => {
            return (
              <span className='status'>{rowData.status}</span>
            );
          }},
        
    ];
  
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
            <div className='view_box'>
                <Button className='add_btn mr-2' label="Process" tooltip="Generate Keys" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={() => setVisible(true)} />
            </div>
        </div>
    );



   

    
   
    return (
        <div className='container_box'>
        <div className="grid card">
            <div className='col-12 page_title'>
                <h5 className='mb-0 mt-2'>Settings</h5>
            </div>
            {/* <div className="col-12 md:col-12">
                <DataTable value={data} showGridlines header={header}
                 paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                 globalFilter={globalFilter}  emptyMessage="No records found">
                    {columns.map((column) => (
                        <Column
                            key={column.field}
                            field={column.field}
                            header={column.header}
                            headerStyle={column.headerStyle}
                            body={column.body}
                        />
                        
                    ))}
                </DataTable>
            </div> */}
        </div>
    </div>
    );
}

export default Settings_list
