import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { useLocation, useNavigate } from 'react-router-dom';
import useCommunications from '../Communications/hook';
import { useStateValue } from '../../../context';
import { _setObject } from '../../app/action';


const Checkins_Details = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { state } = location || {};
    const [{ app }, dispatch] = useStateValue();
    const { Properties_List = [], Communications_List = [], Property = '' } = app || {};
    const [{ CommunicationsListHandler }, { }] = useCommunications();
    const [checked, setChecked] = useState(false);
    const { communications, idScanAttempts, paymentAttempts, reservation, checkinOutDetails, keyReservation, sms, emilqr, smsqr, paymentpolicy, addon } = state || {};
    const { reservationId, pmsReservationId, propertyId, chainId, guestId, sundries, accountId, checkinDate, checkoutDate, roomId, roomName, keyPinCode, isIdScanExempted,
        isIdScanAttempted, accommodationAmount, preAuthAmount, roomTypeId, roomTypeName, isConsentUpdated,
        isAccommodationPaymentExempted, isPaymentSuccesful, isPreAuthSuccesful, isKeyPinSent, isConfirmarionSlipPrinted, status, isAddonPaymentExempted, guest, } = reservation || {};
    const { checkInOutDateTime, isQrCodeScanned, enteredReservationNo, lastUpdated, createdDate, } = checkinOutDetails || {};
    const { firstName, lastName, pmsGuestId, email, mobile, } = guest || {};
    const { keyCode, keyName } = keyReservation || {};
    useEffect(() => {
        if (Properties_List?.length > 0 && Property?.propertyId) {
            CommunicationsListHandler(Property?.propertyId);
        }
    }, [Property])

    const cancel = () => {
        navigate('/check-ins');
    }

    const targetReservation = Communications_List?.find(
        (reservation) => reservation?.pmsReservationId === pmsReservationId
    );

    const sundriesTotal = sundries.reduce((a, b) => a + b.amount, 0);
    const accommodationAmountSundriesTotal = accommodationAmount + sundriesTotal;
    const security_deposite = paymentAttempts.filter(item => item.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item.amount || '0.00'
        ));
    const security_surcharge = paymentAttempts.filter(item => item.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item.surchargeAmount || '0.00'
        ))
    const security_status = paymentAttempts.filter(item => item.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item.status || 'Pending'
        ))

    const accommodationAmountSundriessurchargeAmount = paymentAttempts.filter(item => item.paymentTypeName === 'Accommodation/Sundries')
        .map((item, index) => (
            item.surchargeAmount || 0
        ))
    const surchargeAmountTotal = accommodationAmountSundriessurchargeAmount[0] + security_surcharge[0] || 0;
    const totalPayment = security_deposite.length > 0 ? security_deposite[0] + accommodationAmountSundriesTotal : accommodationAmountSundriesTotal;

    return (
        <div className='container_box'>
            <div className="grid card">
                <div className='col-12 page_title'>
                    <h5 className='mb-0 mt-2'>Check-Ins</h5>
                    <Button className='reload_btn' icon="pi pi-times" rounded text raised aria-label="cancel" onClick={cancel} />
                </div>
                <div className="col-12 p-0">
                    <div className="grid">
                        <div className="col-12 md:col-3 Communications_Details">
                            <div className='details_Box details_card'>
                                <div className='Details_title'>
                                    <h5>Communications</h5>
                                </div>
                                <div className='Details_desc_box'>
                                    <ul>
                                        <li className='Details_desc'>
                                            <p>Email With QR Code:</p>
                                            <span className='toggle_btn'><InputSwitch checked={communications[0]?.isEmailSuccess} /></span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>SMS With QR Code:</p>
                                            <span className='toggle_btn'><InputSwitch checked={communications[0]?.isSmsSuccess} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 Reservation_Details">
                            <div className='details_Box details_card'>
                                <div className='Details_title'>
                                    <h5>Reservation Details</h5>
                                </div>
                                <div className='Details_desc_box'>
                                    <ul>
                                        <li className='Details_desc'>
                                            <p>Reservation Number:</p>
                                            <span>{pmsReservationId}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Room Type:</p>
                                            <span>{roomTypeName}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Room Number:</p>
                                            <span>{roomId}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-5 Guest_Details">
                            <div className='details_Box details_card'>
                                <div className='Details_title'>
                                    <h5>Guest Details</h5>
                                </div>
                                <div className='Details_desc_box'>
                                    <ul>
                                        <li className='Details_desc'>
                                            <p>First Name:</p>
                                            <span>{firstName}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Last Name:</p>
                                            <span>{lastName}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Mobile Number:</p>
                                            <span>{mobile ? mobile : '-'}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Email:</p>
                                            <span>{email ? email : '-'}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mt-30">
                    <div className='grid'>
                        <div className="col-12 md:col-6 details_card2_conatiner">
                            <div className='details_Box details_card2'>
                                <div className='Details_title'>
                                    <h5>Key Access Details</h5>
                                </div>
                                <div className='Details_desc_box'>
                                    <ul>
                                        <li className='Details_desc'>
                                            <p>Key PIN Code:</p>
                                            <span>{keyCode || '-'}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Status:</p>
                                            <span>{status == "" ? 'Not Submitted' : "Processed"}</span>
                                        </li>
                                        <li className='Details_desc'>
                                            <p>Key Name:</p>
                                            <span>{keyName || '-'}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='details_Box details_card2 mt-4'>
                                <div className='Details_title'>
                                    <h5>Special Requests</h5>
                                </div>
                                <div className='Details_desc_box'>
                                    <ul>
                                        <li className='Details_desc'>
                                            <p>N/A</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 details_card3_conatiner">
                            <div className='details_Box details_card3'>
                                <div className='Details_title'>
                                    <h5>Payment Details</h5>
                                </div>
                                <div className='Payment_desc_box'>
                                    <div className='row'>
                                        <div className='Title'>
                                            Accommodation Amount
                                        </div>
                                        <div className='Ammonunt_section'>
                                            <div className='Ammonunt'>
                                                {accommodationAmount?.toFixed(2)}
                                            </div>
                                            <div className='Ammonunt_status'>
                                                {/* {isPaymentSuccesful ? 'Paid' : 'Unpaid'} */}
                                                {/* <span><InputSwitch checked={isPaymentSuccesful} /></span> */}
                                                {isPaymentSuccesful ? <i className="pi pi-thumbs-up" ></i> :
                                                    <i className="pi pi-thumbs-down" ></i>}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        sundries.length > 0 ?
                                            sundries.map((item, index) => {
                                                return (
                                                    <div className='row'>
                                                        <div className='Title2'>
                                                            <div className='Title3'>Addons: {item.sundryName}</div>
                                                        </div>
                                                        <div className='Ammonunt_section2'>
                                                            <div className='Ammonunt2'>
                                                                {item.amount?.toFixed(2)}
                                                            </div>
                                                            <div className='Ammonunt'>
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                                {/* {item.isAddOnPaymentSuccess ? 'Paid' : 'Unpaid'} */}
                                                                {/* <span><InputSwitch checked={item.isAddOnPaymentSuccess}  /></span> */}
                                                                {item.isAddOnPaymentSuccess ? <i className="pi pi-thumbs-up" ></i> :
                                                                    <i className="pi pi-thumbs-down" ></i>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) :
                                            <div className='row'>
                                                <div className='Title2'>
                                                    <div className='Title3'>Addons:</div>
                                                </div>
                                                <div className='Ammonunt_section2'>
                                                    <div className='Ammonunt2'>
                                                        {'-'}
                                                    </div>
                                                    <div className='Ammonunt'>
                                                    </div>
                                                    <div className='Ammonunt_status'>
                                                        {/* i need to rezise this inputswitch i need to make it more small */}
                                                        {/* <span><InputSwitch checked={false} /></span> */}
                                                        {security_status[0] === 'Success' ? <i className="pi pi-thumbs-up" ></i> :
                                                            <i className="pi pi-thumbs-down" ></i>}

                                                    </div>
                                                </div>
                                            </div>


                                    }
                                    <div className='row'>
                                        <div className='Title2'>
                                            <div className='Title3'>Total (Accommodation/Addons)</div>
                                        </div>
                                        <div className='Ammonunt_section2'>
                                            <div className='Ammonunt3'>
                                                <p className='border-bottom'>{sundriesTotal?.toFixed(2)}  </p>
                                                <p>{accommodationAmountSundriesTotal?.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='Title'>
                                            Security Deposit
                                        </div>
                                        <div className='Ammonunt_section'>
                                            <div className='Ammonunt border-bottom'>
                                                {security_deposite[0]?.toFixed(2) || '0.00'}
                                            </div>
                                            <div className='Ammonunt_status'>
                                                {/* <InputSwitch checked={security_status[0] === 'Success' ? true : false} /> */}

                                                {security_status[0] === 'Success' ? <i className="pi pi-thumbs-up" ></i> :
                                                    <i className="pi pi-thumbs-down" ></i>}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='Title'>
                                            <b>Total Payment</b>
                                        </div>
                                        <div className='Ammonunt_section'>
                                            <div className='Ammonunt totalpay_border'>
                                                <span className='payment_block'>
                                                    {totalPayment?.toFixed(2)}
                                                    {/* {1158.00} */}
                                                </span>
                                            </div>
                                            <div className='Ammonunt_status'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='Title'>
                                            Total Surcharge
                                        </div>
                                        <div className='Ammonunt_section'>
                                            <div className='Ammonunt'>
                                                {surchargeAmountTotal?.toFixed(2)}
                                            </div>
                                            <div className='Ammonunt_status'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkins_Details
