import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateValue } from '../context'

const PrivateRoute = () => {
    const [{ app }] = useStateValue()
    const { accessToken } = app || {}
    return accessToken ? <Outlet /> : <Navigate to="/" />;
};


export default PrivateRoute