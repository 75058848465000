import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivateRoute from './private'
import Login from '../components/pages/auth/view'
import Arrivals_list from '../components/pages/Arrivals/ArrivalList'
import Communications_List from '../components/pages/Communications/Communications'
import Checkins_List from '../components/pages/Check_ins/Checkins_list'
import Settings_list from '../components/pages/Settings/Settings'
import Support_List from '../components/pages/Support/Support'
import Checkins_Details from '../components/pages/Check_ins/Checkin_Details'
import Layout from './layout';


export default (
    <Routes>
        <Route exact path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
            <Route exact path="/arrivals" element={<Layout><Arrivals_list /></Layout>} />
            <Route exact path="/communications" element={<Layout><Communications_List /></Layout>} />
            <Route exact path="/check-ins" element={<Layout><Checkins_List /></Layout>} />
            <Route exact path="/check-ins/:id" element={<Layout><Checkins_Details /></Layout>} />
            <Route exact path="/settings" element={<Layout><Settings_list /></Layout>} />
            <Route exact path="/support" element={<Layout><Support_List /></Layout>} />
        </Route>
    </Routes>
)
