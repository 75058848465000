import React, { useEffect, useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import Brand_logo from '../../../assets/images/24SevenCheckIn.png'
import TechLabs from '../../../assets/images/TechLabs.png'
import { useNavigate } from "react-router-dom";
import useAuth from './hook';

const Login = () => {
    const toast = useRef(null);
    const [{ login }, { loginLoding, displayBasic }] = useAuth()
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()

    useEffect(() => {
        if (displayBasic) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Invalid username or password',
                life: 3000
            });
        }
    }, [displayBasic])

    const handleLogin = () => {
        login({ username, password })
    };


    return (

        <>
            {loginLoding &&
                (<div className="loading-spinner-overlay-login">
                    <div class="clock"></div>
                </div>)
            }

            <div className='login-container'>
                <Toast ref={toast} />
                <div className='login-page border-round'>
                    <div className="grid grid-container">
                        <div className="col-12 md:col-6 logo-container">
                            <div className='logo-img'>
                                <img src={Brand_logo} alt="24SevenCheckIn" height={140} className="p-2" />
                            </div>
                            <div className='poweredby-img'>
                                <img src={TechLabs} alt='h2TechLabs' height={40} className='' />
                                <p>Designed and Developed by H2TechLabs</p>
                            </div>

                        </div>
                        <div className="col-12 md:col-6 login-details">
                            <div className='login-box'>
                                <div className='logo-img-mob'>
                                    <img src={Brand_logo} alt="24SevenCheckIn" height={140} className="p-2" />
                                </div>
                                <div className="text-center mb-5">
                                    <h1>Login to your account</h1>
                                </div>
                                <div>
                                    <span className="p-float-label mb-3">
                                        <InputText id="User_ID" type='text' value={username} onChange={(e) => { setUsername(e?.target?.value) }} />
                                        <label htmlFor="User_ID">User ID</label>
                                    </span>
                                    <span className="p-float-label">
                                        <InputText id="Password" type="password" value={password}  onKeyDown={(e) => { e.key === 'Enter' && handleLogin() }} onChange={(e) => { setPassword(e?.target?.value) }} />
                                        <label htmlFor="Password">Password</label>
                                    </span>

                                    <div className="signup-btn">
                                        <Button label="Sign In" className="" onClick={handleLogin} />
                                    </div>
                                </div>
                                <div className='poweredby-img-btn'>
                                    <img src={TechLabs} alt='h2TechLabs' height={40} className='' />
                                    <p>Designed and Developed by H2TechLabs</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
}

export default Login
