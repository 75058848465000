import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { useStateValue } from '../../../context';
import useCheckins from './hook';
import { Toast } from 'primereact/toast';
import { _setObject } from '../../app/action';
import { Sidebar } from 'primereact/sidebar';


const Checkins_List = (props) => {
    const toast = useRef(null);
    const [{ app }, dispatch] = useStateValue();
    const { Properties_List = [], Checkins_List = [], Property = '', Checkins_FilterList = [], dialog = false, DialogMsg = {}, FromDate = null, ToDate = null, globalFilter = null, checkinDetails = {}, toggleState = true } = app || {};
    const [{ CheckinsListHandler, CheckinsListFilter }, { listLoding }] = useCheckins();
    console.log("listLoding",listLoding)
    const propertyID = Property?.propertyId;
    const [viewCheckin, setViewCheckin] = useState(false);

    const View_Details = (rowData) => {
        setViewCheckin(true);
        dispatch(_setObject({ checkinDetails: rowData }));
    }

    const { communications, idScanAttempts, paymentAttempts, reservation, checkinOutDetails, keyReservation, sms, emilqr, smsqr, paymentpolicy, addon } = checkinDetails || {};
    const { reservationId, pmsReservationId, propertyId, chainId, guestId, sundries, accountId, checkinDate, checkoutDate, roomId, roomName, keyPinCode, isIdScanExempted,
        isIdScanAttempted, accommodationAmount, preAuthAmount, roomTypeId, roomTypeName, isConsentUpdated,
        isAccommodationPaymentExempted, isPaymentSuccesful, isPreAuthSuccesful, isKeyPinSent, isConfirmarionSlipPrinted, status, isAddonPaymentExempted, guest, } = reservation || {};
    const { checkInOutDateTime, isQrCodeScanned, enteredReservationNo, lastUpdated, createdDate, } = checkinOutDetails || {};
    const { firstName, lastName, pmsGuestId, email, mobile, } = guest || {};
    const { keyCode, keyName } = keyReservation || {};

    const sundriesTotal = sundries?.reduce((a, b) => a + b.amount, 0);
    const accommodationAmountSundriesTotal = accommodationAmount + sundriesTotal;
    const security_deposite = paymentAttempts?.filter(item => item?.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item?.amount || '0.00'
        ));
    const security_surcharge = paymentAttempts?.filter(item => item?.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item?.surchargeAmount || '0.00'
        ))
    const security_status = paymentAttempts?.filter(item => item?.paymentTypeName === 'Security Deposit')
        .map((item, index) => (
            item?.status || 'Pending'
        ))

    const accommodationAmountSundriessurchargeAmount = paymentAttempts?.filter(item => item.paymentTypeName === 'Accommodation/Sundries')
        .map((item, index) => (
            item?.surchargeAmount || 0
        ))
    const surchargeAmountTotal = (accommodationAmountSundriessurchargeAmount?.[0] || 0) + (security_surcharge?.[0] || 0);

    const totalPayment = security_deposite?.length > 0 ? security_deposite[0] + accommodationAmountSundriesTotal : accommodationAmountSundriesTotal;

    useEffect(() => {
        if ((Properties_List.length > 0 && Property?.propertyId) && (Checkins_List?.length === 0)) {
            CheckinsListHandler(Property?.propertyId);
            dispatch(_setObject({ FromDate: null, ToDate: null }));
        }
    }, [Property])


    const refreshCheckins = () => {
        if (Property?.propertyId) {
            CheckinsListHandler(Property?.propertyId);
            dispatch(_setObject({ FromDate: null, ToDate: null, Checkins_List: [], globalFilter: null, Checkins_FilterList: [] }));
        }
    };

    useEffect(() => {
        if (toast.current && dialog) {
            toast.current.show({
                severity: DialogMsg.success ? 'success' : DialogMsg.info ? 'info' : DialogMsg.error ? 'error' : DialogMsg.warning ? 'warn' : 'success',
                summary: DialogMsg.summary,
                detail: DialogMsg.detail || '',
                life: 3000
            });
            const timeoutId = setTimeout(() => {
                if (toast.current) {
                    toast.current.clear();
                    dispatch(_setObject({ dialog: false, DialogMsg: {} }));
                }
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [DialogMsg]);


    const indexedCheckinsList = Checkins_List?.map((item, index) => ({
        ...item,
        index: index + 1,
    }));

    const indexedCheckinsFilList = Checkins_FilterList?.map((item, index) => ({
        ...item,
        index: index + 1, 
    }));

    const dataToDisplay = Checkins_FilterList?.length > 0 ? indexedCheckinsFilList : indexedCheckinsList;


    const columns = [
        {
            field: 'index', header: 'ID', headerStyle: { minWidth: '50px' }, body: (rowData) => {
                return (
                    <>
                        <span>{rowData?.index}</span>
                    </>
                );
            }
        },
        {
            field: 'Res', header: 'Reservation ID', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        <span>{rowData?.reservation?.pmsReservationId}</span>
                    </>
                );
            }
        },
        {
            field: 'Guestname', header: 'Guest Name', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        <span>{rowData?.reservation?.guest?.firstName + ' ' + rowData?.reservation?.guest?.lastName}</span>
                    </>
                );
            }
        },
        {
            field: 'CheckIn', header: 'Check-In', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                var dateParts = rowData?.reservation?.checkinDate.slice(0, 10).split("-");
                var rearrangedDate = dateParts?.reverse().join("-");
                return (
                    <>
                        <div className='Res'>{rearrangedDate}</div>
                    </>
                );
            }
        },
        {
            field: 'CheckOut', header: 'Check-Out', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                var dateParts = rowData?.reservation?.checkoutDate.slice(0, 10).split("-");
                var rearrangedDate = dateParts?.reverse().join("-");
                return (
                    <>
                        <div className='Res'>{rearrangedDate}</div>
                    </>
                );
            }
        },
        {
            field: 'roomName', header: 'Room', headerStyle: { minWidth: '130px' }, body: (rowData) => {
                return (
                    <>
                        <span>{rowData.reservation.roomName}</span>
                    </>
                );
            }
        },
        {
            field: 'Details', header: 'Details', headerStyle: { minWidth: '80px' }, body: (rowData) => {
                return (
                    <>
                        <div className='action_btns'>
                            <Button label="View" icon="pi pi-eye" iconPos="right" onClick={() => View_Details(rowData)} />
                        </div>
                    </>
                );
            }
        },

    ];


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter ? globalFilter : ''} onInput={(e) =>
                    dispatch(_setObject({ globalFilter: e.target.value }))
                } placeholder="Search by Res. ID..." />
            </span>
            <div className='view_box'>
                <Calendar value={FromDate} onChange={(e) => {
                    dispatch(_setObject({ FromDate: e.value, ToDate: e.value }));
                }} placeholder="From Date" showIcon dateFormat="dd/mm/yy" />
                <Calendar value={ToDate} onChange={(e) =>
                    dispatch(_setObject({ ToDate: e.value }))
                } placeholder='To Date' showIcon dateFormat="dd/mm/yy" />
                <Button className='add_btn mr-2' label="Filter" tooltip=""
                    onClick={() => {
                        if (!FromDate || !ToDate) {
                            dispatch(_setObject({ dialog: true, DialogMsg: { warning: 'warning', summary: 'Both From Date and To Date Must Be Selected', detail: '' } }));
                            return;
                        }
                        CheckinsListFilter(propertyID, FromDate, ToDate);
                    }} />
            </div>
        </div>
    );

    const cancel = () => {
        setViewCheckin(false);
    }



    return (
        <div className='container_box'>
            <Toast ref={toast} />

            {dataToDisplay.length === 0 || listLoding &&
                (<div className="loading-spinner-overlay-login">
                    <div className="clock"></div>
                </div>)
            }

            <div className="grid card">
                <div className='col-12 page_title'>
                    <h5 className='mb-0 mt-2'>Check-Ins</h5>
                    <Button className='reload_btn' icon="pi pi-replay" rounded="true" raised="true" text="true" aria-label="Refresh" tooltip="Refresh" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} onClick={refreshCheckins} />
                </div>
                <div className="col-12 md:col-12">
                    <DataTable value={dataToDisplay} className='checkIns_table' showGridlines header={header} dataKey="index"
                        globalFilterFields={['index', 'reservation.pmsReservationId']}
                        paginator rows={10} rowsPerPageOptions={[10, 15, 20, 25, 30, 35, 40, 45, 50]}
                        sortField={['reservation.pmsReservationId']} sortOrder={1}
                        globalFilter={globalFilter} emptyMessage="No records found">
                        {columns.map((column) => (
                            <Column
                                key={column.field}
                                field={column.field}
                                header={column.header}
                                headerStyle={column.headerStyle}
                                body={column.body}
                            />
                        ))}
                    </DataTable>

                    <Sidebar header="New Edge" className={`checkins_details_panel ${toggleState ? 'checkins_panel' : 'checkins_panel_mt12'}`} visible={viewCheckin} pt={{ header: { className: 'shadow-1 z-2 text-2xl text-color font-bold' } }} position="right" blockScroll onHide={() => setViewCheckin(false)} >
                        <div className='container_box'>
                            <div className="grid card">
                                <div className='col-12 page_title'>
                                    <h5 className='mb-0 mt-2'>Check-Ins</h5>
                                    <Button className='reload_btn' icon="pi pi-times" rounded text raised aria-label="cancel" onClick={cancel} />
                                </div>
                                <div className="col-12 p-0">
                                    <div className="grid">
                                        <div className="col-12 md:col-3 Communications_Details">
                                            <div className='details_Box details_card'>
                                                <div className='Details_title'>
                                                    <h5>Communications</h5>
                                                </div>
                                                <div className='Details_desc_box'>
                                                    <ul>
                                                        <li className='Details_desc'>
                                                            <p>Email With QR Code:</p>
                                                            {/* <span className='toggle_btn'><InputSwitch checked={communications  && communications[0]?.isEmailSuccess} /></span> */}
                                                            {communications && communications[0]?.isEmailSuccess ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                <i className="pi pi-thumbs-down-fill" ></i>}
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>SMS With QR Code:</p>
                                                            {/* <span className='toggle_btn'><InputSwitch checked={communications  && communications[0]?.isSmsSuccess} /></span> */}
                                                            {communications && communications[0]?.isSmsSuccess ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                <i className="pi pi-thumbs-down-fill" ></i>}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 Reservation_Details">
                                            <div className='details_Box details_card'>
                                                <div className='Details_title'>
                                                    <h5>Reservation Details</h5>
                                                </div>
                                                <div className='Details_desc_box'>
                                                    <ul>
                                                        <li className='Details_desc'>
                                                            <p>Reservation Number:</p>
                                                            <span>{pmsReservationId}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Room Type:</p>
                                                            <span>{roomTypeName}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Room Number:</p>
                                                            <span>{roomId}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-5 Guest_Details">
                                            <div className='details_Box details_card'>
                                                <div className='Details_title'>
                                                    <h5>Guest Details</h5>
                                                </div>
                                                <div className='Details_desc_box'>
                                                    <ul>
                                                        <li className='Details_desc'>
                                                            <p>First Name:</p>
                                                            <span>{firstName}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Last Name:</p>
                                                            <span>{lastName}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Mobile Number:</p>
                                                            <span>{mobile ? mobile : '-'}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Email:</p>
                                                            <span>{email ? email : '-'}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 p-0 mt-30">
                                    <div className='grid'>
                                        <div className="col-12 md:col-6 details_card2_conatiner">
                                            <div className='details_Box details_card2'>
                                                <div className='Details_title'>
                                                    <h5>Key Access Details</h5>
                                                </div>
                                                <div className='Details_desc_box'>
                                                    <ul>
                                                        <li className='Details_desc'>
                                                            <p>Key PIN Code:</p>
                                                            <span>{keyCode || '-'}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Status:</p>
                                                            <span>{status == "" ? 'Not Submitted' : "Processed"}</span>
                                                        </li>
                                                        <li className='Details_desc'>
                                                            <p>Key Name:</p>
                                                            <span>{keyName || '-'}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='details_Box details_card2 mt-4'>
                                                <div className='Details_title'>
                                                    <h5>Special Requests</h5>
                                                </div>
                                                <div className='Details_desc_box'>
                                                    <ul>
                                                        <li className='Details_desc'>
                                                            <p>N/A</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 details_card3_conatiner">
                                            <div className='details_Box details_card3'>
                                                <div className='Details_title'>
                                                    <h5>Payment Details</h5>
                                                </div>
                                                <div className='Payment_desc_box'>
                                                    <div className='row'>
                                                        <div className='Title'>
                                                            Accommodation Amount
                                                        </div>
                                                        <div className='Ammonunt_section'>
                                                            <div className='Ammonunt'>
                                                                {accommodationAmount?.toFixed(2)}
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                                {/* {isPaymentSuccesful ? 'Paid' : 'Unpaid'} */}
                                                                {/* <span><InputSwitch checked={isPaymentSuccesful} /></span> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        sundries?.length > 0 ?
                                                            sundries?.map((item, index) => {
                                                                return (
                                                                    <div className='row'>
                                                                        <div className='Title2'>
                                                                            <div className='Title3'>Addons: {item.sundryName}</div>
                                                                        </div>
                                                                        <div className='Ammonunt_section2'>
                                                                            <div className='Ammonunt2'>
                                                                                {item.amount?.toFixed(2)}
                                                                            </div>
                                                                            <div className='Ammonunt'>
                                                                            </div>
                                                                            <div className='Ammonunt_status'>
                                                                                {/* {item.isAddOnPaymentSuccess ? 'Paid' : 'Unpaid'} */}
                                                                                {/* <span><InputSwitch checked={item.isAddOnPaymentSuccess}  /></span> */}
                                                                                {/* {item.isAddOnPaymentSuccess ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                                    <i className="pi pi-thumbs-down-fill" ></i>} */}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className='row'>
                                                                <div className='Title2'>
                                                                    <div className='Title3'>Addons:</div>
                                                                </div>
                                                                <div className='Ammonunt_section2'>
                                                                    <div className='Ammonunt2'>
                                                                        {'-'}
                                                                    </div>
                                                                    <div className='Ammonunt'>
                                                                    </div>
                                                                    <div className='Ammonunt_status'>
                                                                        {/* i need to rezise this inputswitch i need to make it more small */}
                                                                        {/* <span><InputSwitch checked={false} /></span> */}
                                                                        {/* {security_status  && security_status[0] === 'Success' ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                            <i className="pi pi-thumbs-down-fill" ></i>} */}

                                                                    </div>
                                                                </div>
                                                            </div>


                                                    }
                                                    <div className='row'>
                                                        <div className='Title2'>
                                                            <div className='Title3'></div>
                                                        </div>
                                                        <div className='Ammonunt_section2'>
                                                            <div className='Ammonunt3'>
                                                                <p className='border-bottom'>{sundriesTotal?.toFixed(2)}  </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='Title2'>
                                                            <div className='Title3'>Total (Accommodation + Addons)</div>
                                                        </div>
                                                        <div className='Ammonunt_section2'>
                                                            <div className='Ammonunt3'>
                                                                <p>{accommodationAmountSundriesTotal?.toFixed(2)}</p>
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                                {/* <InputSwitch checked={security_status[0] === 'Success' ? true : false} /> */}

                                                                {isPaymentSuccesful ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                    <i className="pi pi-thumbs-down-fill" ></i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='Title'>
                                                            Security Deposit
                                                        </div>
                                                        <div className='Ammonunt_section'>
                                                            <div className='Ammonunt border-bottom'>
                                                                {security_deposite && security_deposite[0]?.toFixed(2) || '0.00'}
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                                {/* <InputSwitch checked={security_status[0] === 'Success' ? true : false} /> */}

                                                                {security_status && security_status[0] === 'Success' ? <i className="pi pi-thumbs-up-fill" ></i> :
                                                                    <i className="pi pi-thumbs-down-fill" ></i>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='Title'>
                                                            <b>Total Payment</b>
                                                        </div>
                                                        <div className='Ammonunt_section'>
                                                            <div className='Ammonunt totalpay_border'>
                                                                <span className='payment_block'>
                                                                    {totalPayment?.toFixed(2)}
                                                                    {/* {1158.00} */}
                                                                </span>
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='Title'>
                                                            Total Surcharge
                                                        </div>
                                                        <div className='Ammonunt_section'>
                                                            <div className='Ammonunt'>
                                                                {surchargeAmountTotal?.toFixed(2)}
                                                            </div>
                                                            <div className='Ammonunt_status'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sidebar>

                </div>
            </div>
        </div>
    );
}

export default Checkins_List;