import axios from 'axios';

const PORT = process.env.REACT_APP_PORT
// const BASE_URL = `http://247checkin.ideas2goal.com:${PORT}/relay`
// const BASE_URL = `https://azu-aue-24sevencheckin-adminappservices-dev.azurewebsites.net/relay`
const BASE_URL = `https://admin.24sevencheckin.com.au/relay`

// refresh auth token
const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("kc_refresh_token");
    const response = await axios.post(`${BASE_URL}/auth/refresh`, { refreshToken })
    console.log(response);
    return response?.data?.accessToken
};

// request interceptor to add token to request headers
axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("kc_access_token");

        if (token) {
            config.headers = {
                "x-access-token": token
            };
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// response interceptor intercepting 401 responses, refreshing token and retrying the request
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error.config;

        if (error?.response?.status === 401 && !config._retry) {
            config._retry = true;
            const refreshed_access_token = await refreshAccessToken()
            localStorage.setItem("kc_access_token", refreshed_access_token);
            return axios(config);
        }

        return Promise.reject(error);
    }
);

const relayService = ({ url, method = 'GET', headers, data }) => {
    const axios_instance = axios({ url, method, baseURL: BASE_URL, headers, data })
    return axios_instance
}

export default relayService

